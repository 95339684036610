<template>
  <div class="container_pay">
    <clone-tab></clone-tab>

    <div class="content_p">
      <!--公告 -->
      <div class="title_p" v-if="queryList.type!=1">工会会费是“中华全国总工会”的规定缴纳的会费，是工会组织开展各项活动所需要的费用，工会会员会费：{{tableData.union_member_fee}}元/年。会费可凭借购购指定板块消费凭证上传审核。</div>
      <div class="title_p" v-else>劳务合同是利道工会会员与利道工会有限公司签署的劳务协议，签署后为我司临时员工，签署完成才具备销售权益。</div>
    </div>

    <!-- 地址選擇 -->
    <div class="address_p" v-if="address" @click="selectBtn">
      <div class="address_box1">
        <div style="display:flex">
          <div>联系人：{{address.receiver_name}}</div>
          <div>联系电话：{{address.receiver_mobile}}</div>
        </div>
        <div>详细地址：{{address.receiver_address}}</div>
      </div>
    </div>

    <!-- 地址選擇 -->
    <div class="address_p" @click="selectBtn" v-else>
      <div class="address_title">请选择收货地址：</div>
      <div class="address_box">请选择收货地址</div>
    </div>

    <!-- 支付方式 -->
    <pay-status @radioBtn="radioBtn"></pay-status>

    <!-- 线下支付 -->
    <online-pay @uploadImg="uploadImg" v-if="payStatus==1"></online-pay>

    <!-- 支付金額 -->
    <div class="priceText" v-if="queryList.type!=1">实际支付金额：￥<span>{{tableData.union_member_fee}}</span></div>
    <div class="priceText" v-else>实际支付金额：￥<span>{{tableData.shareholder_renew_shipping_fee}}</span></div>

    <!-- 信息備注 -->
    <div class="text_box1">
      <div>信息备注：</div>
      <textarea v-model="message"></textarea>
    </div>

    <div class="submitBtn_p" @click="submitBtn">提交</div>
  </div>
</template>
<script>
import cloneTab from "../../../components/expenditure/cloneTab";
import payStatus from "../../../components/expenditure/payStatus";
import onlinePay from "../../../components/expenditure/onlinePay";
import { Toast } from "vant";
import * as apiCommon from "@/api/common";
import wx from "weixin-js-sdk";
import {
  payApplyDetail_api,
  shareDetail_api,
  applyDetail_api,
  payShareDetail_api
} from "@/api/deal";
export default {
  data() {
    return {
      payStatus: 1,
      address: "",
      dataList: "",
      message: "",
      img: "",
      queryList: null,
      tableData: {}
    };
  },
  components: {
    cloneTab,
    payStatus,
    onlinePay
  },
  created() {
    document.title = "支付页面";
    this.queryList = this.$route.query;
    if (JSON.parse(localStorage.getItem("address"))) {
      this.address = JSON.parse(localStorage.getItem("address"));
    }
    console.log(this.address);
    if (this.queryList.type == 1) {
      this.getDetails1(this.$route.query.id);
    } else {
      this.getDetails(this.$route.query.id);
    }
    this.getSetting();
  },

  methods: {
    async getSetting() {
      let data = {
        config: ["union_member_fee", "shareholder_renew_shipping_fee"]
      };
      let res = await apiCommon.configSetting(data);
      if (res.code == 0) {
        console.log(res.data, "111111");
        this.tableData = res.data;
      }
    },
    uploadImg(data) {
      this.img = data;
    },
    getDetails(id) {
      applyDetail_api(id).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.dataList = res.data;
        }
      });
    },
    getDetails1(id) {
      shareDetail_api(id).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.dataList = res.data;
        }
      });
    },
    submitBtn() {
      console.log(this.img)
      let data = {
        pay_info: {
          message: this.message
        },
        audit_shipments: {
          receiver_name: this.address.receiver_name,
          receiver_address: this.address.receiver_address,
          receiver_mobile: this.address.receiver_mobile
        },
        pay_type: this.payStatus
      };
      if (!this.address) {
        Toast({
          message: "请选择地址",
          icon: "none",
          duration: 1000
        });
        return false;
      }
      if (this.payStatus == 1) {
        data.pay_info.payImg = this.img;
        if (!this.img) {
          Toast({
            message: "请上传凭证",
            icon: "none",
            duration: 1000
          });
          return false;
        }
      }
      if (this.queryList.type == 1) {
        payShareDetail_api(data, this.queryList.id).then(res => {
          if (res.code == 0) {
            if (this.payStatus == 2) {
              // 微信支付
              this.wxPay();
            } else {
              this.$router.go(-1);
            }
          } else {
            this.$toast("暂时无法支付");
          }
        });
      } else {
        payApplyDetail_api(data, this.queryList.id).then(res => {
          if (res.code == 0) {
            if (this.payStatus == 2) {
              // 微信支付
              this.wxPay(res);
            } else {
              this.$router.go(-1);
            }
          } else {
            this.$toast("暂时无法支付");
          }
        });
      }
    },
    async wxPay(res) {
      let that = this;
      Toast.loading({
        message: "加载中...",
        forbidClick: true
      });
      let res1 = await apiCommon.getConfig();
      console.log(res1);
      let tableData = res1.data;
      wx.config(tableData);
      wx.chooseWXPay({
        timestamp: res.data.pay_info.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: res.data.pay_info.nonceStr, // 支付签名随机串，不长于 32 位
        package: res.data.pay_info.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: "MD5", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: res.data.pay_info.paySign, // 支付签名
        success(res) {
          if (res.errMsg == "chooseWXPay:ok") {
            that.$toast("支付成功");
            if (that.type!= 1) {
              that.$router.push(
                `./contract?type=1&id=${
                that.queryList.id
              }`
              );
            } else {
              that.$router.push(
                `./contract?id=${
                that.queryList.id
              }`
              );
            }
          } else {
            alert(res.errMsg);
            that.$toast("支付失败", "middle");
          }
        },
        cancel: function(res) {
          that.$toast("支付取消", "middle", res);
        }
      });
    },
    radioBtn(index) {
      this.payStatus = index;
    },
    selectBtn() {
      this.$router.push("../../address");
    }
  }
};
</script>
<style>
.container_pay {
  padding-top: 40px;
  padding-bottom: 150px;
  background-image: url(../../../assets/Slice51.png);
  background-size: 100% 100%;
  padding-bottom: 100px;
}
.content_p {
  padding: 0px 58px 0 56px;
  box-sizing: border-box;
  margin-top: 40px;
}
.title_p {
  font-size: 30px;
  color: #eed09c;
  letter-spacing: 2px;
  line-height: 40px;
}
.address_p {
  margin-top: 30px;
  margin-left: 35px;
}
.address_title {
  font-size: 30px;
  color: #fff;
  margin-left: 10px;
}
.address_box {
  width: 680px;
  height: 170px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(204, 175, 124, 0.6);
  line-height: 170px;
  text-align: center;
  font-size: 30px;
  color: #e3c084;
  margin: 36px 0;
}
.address_box1 {
  width: 680px;
  height: 170px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(204, 175, 124, 0.6);
  font-size: 30px;
  line-height: 40px;
  color: #e3c084;
  margin: 36px 0;
  padding: 12px 26px;
  box-sizing: border-box;
}
.wxPay,
.pricePay {
  display: flex;
  font-size: 30px;
  color: #e3c084;
  line-height: 50px;
}
.wxPay img {
  width: 50px;
  height: 46px;
  margin: 0 30px;
}
.pricePay img {
  width: 64px;
  height: 64px;
  margin: 0 30px;
}
.priceText {
  font-size: 30px;
  color: #fff;
  text-align: right;
  margin-right: 55px;
}
.priceText span {
  font-size: 50px;
}
.text_box1 {
  margin-top: 18px;
  font-size: 30px;
  color: #fff;
  margin-left: 50px;
  margin-bottom: 100px;
}
.text_box1 div {
  margin-bottom: 30px;
}
.text_box1 textarea {
  background-color: transparent;
  width: 640px;
  height: 276px;
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  border: 2px solid rgba(204, 175, 124, 0.5);
  padding: 20px;
  box-sizing: border-box;
  margin: 0;
}
.submitBtn_p {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 489px;
  height: 80px;
  background: #d6b06f;
  box-shadow: inset 0px 4px 5px 0px rgba(255, 255, 255, 0.3);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  text-align: center;
  line-height: 80px;
  font-size: 35px;
  color: #fff;
}
.van-toast {
  width: 300px;
  min-height: 120px;
}
.van-toast__text {
  font-size: 35px;
}
.van-icon {
  font-size: 40px;
}
.van-dialog {
  width: 500px;
  font-size: 35px !important;
}
.van-dialog__message {
  font-size: 25px;
  line-height: 40px;
}
</style>
